import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./badge.scss";

const Badge = ({ children, design }) => {
	const badgeClassName = classNames({
		badge: true,
		"badge--blue": design === "blue",
	});

	return <div className={badgeClassName}>{children}</div>;
};

Badge.propTypes = {
	children: PropTypes.node,
	design: PropTypes.oneOf(["blue"]),
};

export default Badge;
