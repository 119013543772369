import React from "react";
import PropTypes from "prop-types";
import "./BackButton.scss";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconBack from "app/pages/.shared/static/icons/IconBack";
import { sendTagOnBackButton } from "app/utils/analytics";
import { withRouter } from "react-router";
import get from "lodash/get";

class BackButtonComponent extends React.PureComponent {
	constructor() {
		super();
		this.backHistory = this.backHistory.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		const { location } = this.props;

		sendTagOnBackButton(location.pathname);
		if (typeof this.props.onClick === "function") {
			this.props.onClick();
		}
	}

	backHistory() {
		if (typeof window !== "undefined") {
			this.onClick();
			window.history.back();
		}
	}

	render() {
		const { to, label = "general.back", noBrowserBack } = this.props;

		if (
			(typeof window !== "undefined" && window.history.length === 1) ||
			get(to, "referrer") ||
			get(to, "merchCode") ||
			noBrowserBack
		) {
			return (
				<div className="back-button">
					<RelativeLink
						design="naked"
						to={{ ...to }}
						onClick={this.onClick}
						data-cy="back-button"
					>
						<IconBack width={15} height={15} style={{ marginRight: "5px" }} />
						<div className="back-button__label">
							{label && <FormattedMessage id={label} />}
						</div>
					</RelativeLink>
				</div>
			);
		}

		return (
			<div className="back-button" onClick={this.backHistory} data-cy="back-button">
				<IconBack width={15} height={15} style={{ marginRight: "5px" }} />
				<div className="back-button__label">{label && <FormattedMessage id={label} />}</div>
			</div>
		);
	}
}

BackButtonComponent.propTypes = {
	to: PropTypes.object,
	onClick: PropTypes.func,
	label: PropTypes.string,
	noBrowserBack: PropTypes.bool,
	location: PropTypes.object,
};

export const BackButton = withRouter(BackButtonComponent);
