import PropTypes from "prop-types";
import React from "react";

const AmountSign = ({ sign }) => {
	return <span style={{ fontFamily: "arial" }}>{sign}</span>;
};

AmountSign.propTypes = {
	sign: PropTypes.string,
};

export default React.memo(AmountSign);
