import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "app/pages/Auth/AuthActions";
import AsideMenu from "@brand/AsideMenu/AsideMenu";
import { isWhiteLabel } from "app/reducers/brandSelector";
import { hideAsideMenu } from "app/pages/Header/menuActionCreators";
import { isAuthenticated } from "app/pages/Auth/authSelectors";

const mapStateToProps = state => {
	return {
		userIsConnected: isAuthenticated(state),
		helpContactUrl: state.callCenter.helpContactUrl,
		partnercode: state.partner.code,
		strategy: state.partner.strategy,
		enableSponsorship: state.partner.enableSponsorship,
		legalesDocumentName: state.documents.legales,
		isWhiteLabel: isWhiteLabel(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ logout, onItemClick: hideAsideMenu }, dispatch);
};

export const AsideMenuAccountContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(AsideMenu);
