import React, { useState, useEffect } from "react";
import "./AutoplayCarousel.scss";

import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
export const AutoplayCarousel = ({ children, slideWidth = 250, duration = 20000 }) => {
	const [running, setRunning] = useState(1); // 0 reset, 1 run
	useEffect(() => {
		if (running === 0) {
			setRunning(1);
		}
	}, [running === 0]);

	/**
	 * pour faire un carousel infinite
	 * https://github.com/react-spring/react-spring/issues/85
	 */
	const props = useSpring({
		from: { transform: "translate(0px)" },
		to: async next => {
			await next({
				transform: `translate(-${children.length * slideWidth}px)`,
			});
		},
		reset: running === 0,
		onRest: () => {
			setRunning(0);
		},

		config: { duration: duration },
	});

	return (
		<div className="autoplay-carousel">
			<animated.div style={props} className="slide-track">
				{React.Children.map(children, item => (
					<div className="slide">{item}</div>
				))}
				{React.Children.map(children, item => (
					<div className="slide">{item}</div>
				))}
			</animated.div>
		</div>
	);
};

AutoplayCarousel.propTypes = {
	slideWidth: PropTypes.number,
	duration: PropTypes.number,
};
