import PropTypes from "prop-types";
import React from "react";

const AirlineLogo = ({ airlineCode }) => {
	return (
		<img
			className="airline-logo"
			src={`https://res.cloudinary.com/perfectstay/image/upload/e_trim,f_auto,q_auto/if_h_gt_30,h_30/v1/airline/${airlineCode}.png`}
			alt={airlineCode}
		/>
	);
};

AirlineLogo.propTypes = {
	airlineCode: PropTypes.string,
};

export default React.memo(AirlineLogo);
