import React from "react";
import AsidePanel from "app/pages/.shared/AsidePanel/AsidePanel";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { hideAsideMenu, toggleAsideMenu } from "app/pages/Header/menuActionCreators";
import PropTypes from "prop-types";

class AsidePanelContainer extends React.Component {
	render() {
		return <AsidePanel {...this.props} />;
	}
}

AsidePanelContainer.propTypes = {
	renderContent: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		isOpen: state.menu.isAsideMenuOpened,
		shop: state.shop,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ toggleAsideMenu, hideAsideMenu }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AsidePanelContainer);
