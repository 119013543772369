import { HIDE_ASIDE_PRODUCT_FILTERS, TOGGLE_ASIDE_PRODUCT_FILTERS } from "app/actionTypes";
import { sendTagOnClickFilterMobile } from "app/utils/analytics";

export const toggleAsideProductFilters = () => {
	sendTagOnClickFilterMobile();
	return {
		type: TOGGLE_ASIDE_PRODUCT_FILTERS,
	};
};

export const hideAsideProductFilters = () => {
	return {
		type: HIDE_ASIDE_PRODUCT_FILTERS,
	};
};
