import Amount from "app/pages/.shared/Amount";
import { connect } from "react-redux";

const mapStateToProps = state => {
	return {
		currencyCode: state.partner.currencyCode,
	};
};

export default connect(mapStateToProps)(Amount);
