import PropTypes from "prop-types";
import React from "react";
import { registerStyle } from "app/utils/CriticalCSS";
import styleCritical from "./alert.scss";
import onClickOutside from "react-onclickoutside";
export class Alert extends React.Component {
	constructor(props) {
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	handleClickOutside(event) {
		const { alertClickOutside = true } = this.props;
		event.preventDefault();

		if (alertClickOutside) {
			this.props.onClose();
		}
	}

	render() {
		const {
			alertTitle = "",
			alertMessage = "",
			alertActionNodes = false,
			onConfirm,
			alertSubtitle = "",
		} = this.props;
		const headerNode = alertTitle ? (
			<header className="alert__title">{alertTitle}</header>
		) : (
			false
		);

		return (
			<div className="alert">
				{headerNode}
				<div className="alert__message">
					{alertSubtitle && <div className="alert__subtitle">{alertSubtitle}</div>}
					{alertMessage}
				</div>
				<div className="alert__action">
					{alertActionNodes &&
						alertActionNodes.map((alertActionNode, index) => (
							<div
								onClick={onConfirm}
								className="alert__action__item"
								key={`alert-action-node-${index}`}
							>
								{alertActionNode}
							</div>
						))}
				</div>
			</div>
		);
	}
}
Alert.propTypes = {
	alertTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	alertSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	alertActionNodes: PropTypes.arrayOf(PropTypes.element),
	alertClickOutside: PropTypes.bool,
	onConfirm: PropTypes.func,
	onClose: PropTypes.func,
};

export default registerStyle(styleCritical)(onClickOutside(Alert));
