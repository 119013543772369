import React from "react";
import AsidePanel from "app/pages/.shared/AsidePanel/AsidePanel";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
	toggleAsideProductFilters,
	hideAsideProductFilters,
} from "app/pages/.shared/AsidePanel/AsideProductFilters/AsideProductFiltersActionCreators";
import PropTypes from "prop-types";

class AsideProductFiltersComponent extends React.Component {
	render() {
		return <AsidePanel {...this.props} />;
	}
}

AsideProductFiltersComponent.propTypes = {
	renderContent: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		isOpen: state.menu.isAsideProductFilterOpened,
		brand: state.brand.code,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ toggleAsideProductFilters, hideAsideProductFilters }, dispatch);
};

export const AsideProductFiltersContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(AsideProductFiltersComponent);
